<template>
  <!-- Table tree -->
  <div class="card">
    <div class="card-header header-elements-inline">
      <h6 class="card-title">Table tree</h6>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="collapse"></a>
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="table-responsive">
        <table class="tree table table-bordered">
          <tr class="treegrid-1">
            <td>Root node</td><td>Additional info</td>
          </tr>
          <tr class="treegrid-2 treegrid-parent-1">
            <td>Node 1-1</td><td>Additional info</td>
          </tr>
          <tr class="treegrid-3 treegrid-parent-1">
            <td>Node 1-2</td><td>Additional info</td>
          </tr>
          <tr class="treegrid-4 treegrid-parent-3">
            <td>Node 1-2-1</td><td>Additional info</td>
          </tr>
        </table>


      </div>
    </div>
  </div>
  <!-- /table tree -->

</template>

<script>
  import moment from 'moment-timezone'
  import { userService } from '@/store/auth-header.js'

  export default {
    name: 'StockHistoryView',
    components: {

    },
    data () {
      return {
        color: { 'code': 0, 'name': '' },
        colors: []
      }
    },

    mounted () {
      $('.tree').treegrid({
        treeColumn: 1
      });
    },
    updated () {
      // $(this.$refs.color).selectpicker('refresh')
    },
    methods: {
      typeFormatter (value, row, index) {
        if (value === 'menu') {
          Return
          ' menu '
        }
        if (value === 'button') {
          Return
          ' button '
        }
        if (value === 'api') {
          Return
          ' interface '
        }
        return '-'
      },
      statusFormatter (value, row, index) {
        if (value === 1) {
          return '<span class="label label-success">正常</span>'
        }
        return '<span class="label label-default">锁定</span>'
      },
    }
  }
</script>

<style scoped>

</style>
